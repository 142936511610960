import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "gatsby-link";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "thumbnail.png" }) {
        publicURL
      },
      allDataJson {
        nodes {
          pediatricians {
            videos {
              title
              featured_video {
                id
                title
              }
            }
            footnote
          }
        }
      }
      allVimeoVideo(sort: {fields: title}) {
        nodes {
          id
          description
          iframe
          title
          url
          thumbnail {
            medium
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Pediatra" />
      <div className={"page bg-white overflow-hidden"}>

        <Row className={"bg-white justify-content-center pt-page mb-4"}>
          <Col className={"col-auto"}>
            <h1 className={"text-center px-2"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.videos.title}}/>
          </Col>
        </Row>

        <Row className={"bg-light mt-4"}>
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className={"px-3 py-3 align-items-center"}>
            <Row className={""}>
              { data.allVimeoVideo.nodes.filter((v) => v.description !== 'Camilla' ).map((video, i) => (
                  <Col key={i} xs={{ span: 10 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 3 }} className={"p-3 align-items-center"}>
                <Link to={`/pediatra/videos/${video.id}`} className={"video-link"}>
                    <img src={data.logo.publicURL} alt={video.title} className={"mb-2"} style={{ width: "100%" }}/>
                  <i className="fas fa-play-circle"/>
                    <p className={"text-center video-title-wrap"}>{ video.title }</p>
                </Link>
                  </Col>
              ))}
            </Row>
          </Col>
        </Row>



        <Row className={"bg-white section-5 justify-content-center pt-4 pb-4"}>
          <Col xs={10} md={{ span: 8}} className={"text-center"}>
            <p className={"mb-2"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.footnote}} />
          </Col>
        </Row>

      </div>
    </Layout>
  )
}

export default IndexPage
